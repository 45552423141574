<template>
  <div class="zoomConfig">
    <v-form ref="form" v-model="valid">
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="15"
        loading-text="Loading... Please wait"
        class="elevation-1 level1"
        hide-default-footer
      >
        <template v-slot:top>
          <v-row class="">
            <v-col cols="7" sm="6" md="6">
              <h3
                class="custom-header main-color"
                :class="currentAppLocale == 'ar' ? 'text-right' : ''"
              >
                {{ $t("Zoom Configuration") }}
              </h3>
            </v-col>

            <v-col cols="5" sm="6">
              <div
                :class="
                  currentAppLocale == 'en' || currentAppLocale == undefined
                    ? 'text-right'
                    : 'text-left'
                "
              >
                <!-- <button
                  icon
                  @click.prevent="openSettingModel"
                  class="main-color"
                >
                  <v-icon color="#7297ff" :title="$t('Zoom Settings')"
                    >video_settings</v-icon
                  >
                </button> -->
                <v-btn
                  icon
                  @click="openEditMode()"
                  v-if="!edit"
                  class="main-color"
                  :title="$t('Edit')"
                >
                  <v-icon>edit</v-icon>
                </v-btn>

                <!-- ================== Activity logs ================== -->
                <v-btn
                  icon
                  to="/systemlogging/zoom_configuration"
                  v-if="activitylogPermission"
                  target="_blank"
                  class="mt-2"
                >
                  <v-icon class="main-color" large :title="$t('System Log')"
                    >mdi-archive-clock</v-icon
                  >
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </template>

        <template v-slot:item.email="{ item }">
          <span v-if="!edit">{{ item.email }}</span>
          <v-text-field
            v-model="item.email"
            v-if="edit"
            @focus="onFocus()"
            solo
            :rules="[
              validateEmail(
                item.email,
                item.api_key,
                item.api_secret_key,
                item.jwt_token
              ),
            ]"
            class="zoomConfigMeeting"
          ></v-text-field>
          <!-- <div
            v-if="validation_errors.configration && edit && item.email"
            class="validation"
          >
            <p
              class="error--text"
              v-if="
                validation_errors.configration.account_username &&
                  validation_errors.configration.account_username.length > 0
              "
            >
              {{ validation_errors.configration.account_username[0] }}
            </p>
          </div>-->

          <!-- <div
            v-if="validation_errors && edit && item.email"
            class="validation"
          >
            <p
              class="error--text"
              v-if="
                validation_errors.email && validation_errors.email.length > 0
              "
            >
              {{ validation_errors.email[0] }}
            </p>
          </div>-->
        </template>
        <template v-slot:item.api_key="{ item }">
          <!-- <span v-if="!edit">{{ item.api_key }}</span> -->

          <v-tooltip bottom max-width="300px" v-if="!edit">
            <template v-slot:activator="{ on }">
              <p dark v-on="on" v-if="item.api_key">
                {{ item.api_key.slice(0, 20) }}
              </p>
            </template>
            <span>{{ item.api_key }}</span>
          </v-tooltip>

          <v-text-field
            v-model="item.api_key"
            v-if="edit"
            @focus="onFocus()"
            solo
            class="zoomConfigMeeting"
          ></v-text-field>
        </template>
        <template v-slot:item.api_secret_key="{ item }">
          <!-- <span v-if="!edit">{{ item.api_secret_key }}</span> -->

          <v-tooltip bottom max-width="300px" v-if="!edit">
            <template v-slot:activator="{ on }">
              <p dark v-on="on" v-if="item.api_secret_key">
                {{ item.api_secret_key.slice(0, 20) }}
              </p>
            </template>
            <span>{{ item.api_secret_key }}</span>
          </v-tooltip>

          <v-text-field
            v-model="item.api_secret_key"
            v-if="edit"
            @focus="onFocus()"
            solo
            class="zoomConfigMeeting"
          ></v-text-field>
        </template>
        <template v-slot:item.jwt_token="{ item }">
          <!-- <span v-if="!edit">{{ item.jwt_token }}</span> -->

          <v-tooltip bottom max-width="300px" v-if="!edit">
            <template v-slot:activator="{ on }">
              <p dark v-on="on" v-if="item.jwt_token">
                {{ item.jwt_token.slice(0, 20) }}
              </p>
            </template>
            <span>{{ item.jwt_token }}</span>
          </v-tooltip>

          <v-text-field
            v-model="item.jwt_token"
            v-if="edit"
            @focus="onFocus()"
            solo
            class="zoomConfigMeeting"
          ></v-text-field>
        </template>
      </v-data-table>
    </v-form>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="length"
        :page="page"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
      ></v-pagination>
      <!-- :total-visible="5" -->
    </div>

    <div class="text-right" v-if="edit">
      <v-btn class="modal-btn-save saveBtn" @click="cancel">{{
        $t("Cancel")
      }}</v-btn>
      <v-btn
        class="modal-btn-save saveBtn"
        :loading="loading"
        @click="saveZoomConfigration"
        >{{ $t("Save") }}</v-btn
      >
    </div>

    <!-- video setting modal -->
    <v-dialog v-model="settingModal" persistent max-width="500px" scrollable>
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $t("Meeting Configuration Setting")
          }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="zoomform" class="py-4">
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <label>{{ $t("API KEY") }}</label>
                  <v-text-field v-model="configObj.api_key" solo></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="6">
                  <label>{{ $t("API SECRETE NAME") }}</label>
                  <v-text-field
                    v-model="configObj.api_secret_key"
                    solo
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  style="padding-right: 0 !important"
                >
                  <label>{{ $t("JWT Token") }}</label>
                  <v-text-field
                    v-model="configObj.jwt_token"
                    solo
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn class="modal-btn-cancel cancelBtn" @click="closeModel">{{
            $t("Close")
          }}</v-btn>
          <v-btn
            class="modal-btn-save saveBtn"
            :loading="loading"
            @click="saveModel()"
            >{{ $t("Save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      :color="snackColor"
      class="white--text"
      :top="true"
      :right="true"
      :timeout="timeout"
    >
      {{ snackText }}
      <v-btn color="pink" text @click="snackbar = false">{{
        $t("Close")
      }}</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import ACL from "../../acl";

export default {
  name: "zoomConfiguration",
  data() {
    return {
      activitylogPermission: ACL.checkPermission("activitylog"),

      // snackbar
      snackbar: false,
      snackText: "",
      snackColor: "error",
      timeout: 6000,
      loading: false,
      page: 1,
      length: 0,
      search: "",
      edit: false,
      settingModal: false,
      valid: false,
      validationRules: {
        email: (v) =>
          /^\w+([\\.-]?\w+)*@\w+?\.\w{2,100}$/.test(v) ||
          "Please enter a valid format",
      },
      headers: [
        {
          text: this.$i18n.t("Class Name"),
          align: "start",
          sortable: false,
          value: "class",
        },
        { text: this.$i18n.t("Email"), value: "email", sortable: false },
        { text: this.$i18n.t("User ID"), value: "userId", sortable: false },
        { text: this.$i18n.t("API Key"), value: "api_key", sortable: false },
        {
          text: this.$i18n.t("API Secret Key"),
          value: "api_secret_key",
          sortable: false,
        },
        {
          text: this.$i18n.t("JWT Token"),
          value: "jwt_token",
          sortable: false,
        },
      ],
      items: [],

      configObj: {
        api_key: "",
        api_secret_key: "",
        jwt_token: "",
      },
      validation_errors: {},
    };
  },
  watch: {
    page: {
      handler() {
        if (this.page != "" && this.page) {
          this.$router.push(
            { path: "/workingdays?tab=2&page=" + this.page },
            () => {}
          );
          this.showData();
        }
      },
    },
  },
  methods: {
    inputFocus() {
      $("#inpt_search").parent("label").addClass("active");
      $(".input_search").parent("label").addClass("active");
    },
    inputBlur() {
      if (this.search == "") {
        $("#inpt_search").parent("label").removeClass("active");
        $(".input_search").parent("label").removeClass("active");
      }
    },
    openEditMode() {
      this.edit = true;
    },
    saveZoomConfigration() {
      if (this.valid == false) {
        this.$refs.form.validate();
        console.log("validation");
      } else {
        this.loading = true;
        axios
          .post(
            this.getApiUrl + "/online_meeting/save_configuration",
            this.items,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.loading = false;
            if (response.data.status.error == true) {
              this.validation_errors = response.data.status.validation_errors;

              if (this.validation_errors.configration) {
                if (
                  this.validation_errors.configration.account_username &&
                  this.validation_errors.configration.account_username.length >
                    0
                ) {
                  this.snackText =
                    this.validation_errors.configration.account_username[0];
                  this.snackbar = true;
                }

                if (
                  this.validation_errors.configration.jwt_token &&
                  this.validation_errors.configration.jwt_token.length > 0
                ) {
                  this.snackbar = true;
                  this.snackText =
                    this.validation_errors.configration.jwt_token[0];
                }
              }

              if (this.validation_errors) {
                if (this.validation_errors.email) {
                  this.snackText = this.validation_errors.email[0];
                  this.snackbar = true;
                }
              }
            } else {
              this.edit = false;
              this.showData();
            }
          });
      }
    },
    showData() {
      this.loading = true;
      if (this.page) {
        axios
          .get(
            this.getApiUrl +
              "/online_meeting/get_configuration?page=" +
              this.page,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            // console.log(response);
            this.items = response.data.data.data;
            this.page = response.data.data.current_page;
            this.length = response.data.data.last_page;
          });
      } else {
        axios
          .get(this.getApiUrl + "/online_meeting/get_configuration", {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          })
          .then((response) => {
            // console.log(response);
            this.items = response.data.data.data;
            this.page = response.data.data.current_page;
            this.length = response.data.data.last_page;
          });
      }
      this.loading = false;
    },
    cancel() {
      this.edit = false;
      this.showData();
    },
    openSettingModel() {
      this.settingModal = true;
    },
    saveModel() {
      this.items.forEach((item) => {
        item.api_key = this.configObj.api_key;
        item.api_secret_key = this.configObj.api_secret_key;
        item.jwt_token = this.configObj.jwt_token;
      });
      this.settingModal = false;
      this.edit = true;

      this.configObj.api_key = "";
      this.configObj.api_secret_key = "";
      this.configObj.jwt_token = "";
    },
    closeModel() {
      this.configObj.api_key = "";
      this.configObj.api_secret_key = "";
      this.configObj.jwt_token = "";
      this.settingModal = false;
    },

    validateEmail(email, api_key, api_secret_key, jwt_token) {
      if (api_key && api_secret_key && jwt_token) {
        if (!email || email == "") {
          return this.$i18n.t("This field is required");
        } else if (email != "") {
          if (!/^\w+([\\.-]?\w+)*@\w+?\.\w{2,100}$/.test(email)) {
            return "Please enter a valid format";
          }
        }
      } else {
        return true;
      }
    },
    onFocus() {
      this.validation_errors = {};
    },
  },
  mounted() {
    this.page = this.$route.query.page;
    this.showData();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";

.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}

.v-text-field.v-text-field--solo .v-input__control {
  min-height: 35px !important;
}

.v-text-field__details {
  margin-bottom: 10px !important;
}

@media (max-width: 600px) {
  .custom-header {
    font-size: 18px;
  }
}
</style>
